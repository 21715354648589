import React from "react";
// 3rd party libraries
import axios from "axios";
import { connect } from "react-redux";
import { Button, message } from "antd";
import { Link } from "react-router-dom";
// custom components
import config from "../config";
import { register, logoutUser } from "../appRedux/actions/AuthActions";
import { get } from "lodash";

class VerifyEmail extends React.PureComponent {
  handleSendVerificationEmail = () => {
    axios
      .post(`${config.API_URL}/company/subcontractor/verify-email`)
      .then((response) => {
        if (get(response, "data.success")) {
          message.success("Verification email sent successfully!");
        } else {
          message.error(
            "Verification email conanot be sent. Please Try again in a few minutes"
          );
        }
      });
  };

  componentDidMount() {
    if (get(this.props, "auth.user.verified")) {
      window.location.href = "/login";
    }
  }

  render() {
    return (
      <div className="gx-app-login-wrap" style={{ marginTop: "37px" }}>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img
                  src={
                    "https://s3.us-east-1.wasabisys.com/constructions/static/acts-round.png"
                  }
                  alt="Neature"
                />
              </div>
              <div className="gx-app-logo-wid"></div>
            </div>
            <div className="gx-app-login-content">
              <h2>Verify your Email</h2>
              <Button type="primary" onClick={this.handleSendVerificationEmail}>
                Send Verification Email
              </Button>
              <div>
                <Link
                  to="/login"
                  onClick={() => this.props.logoutUser()}
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <span>Back to login </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.authR,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  register,
  logoutUser,
})(VerifyEmail);
