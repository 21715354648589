import React from "react";
import { Avatar, Popover } from "antd";
import { connect } from "react-redux";
import { logoutUser } from "../../appRedux/actions/AuthActions";
import { Link } from "react-router-dom";
const UserInfo = (props) => {
  const { role } = props.auth.user;

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => props.logoutUser()}>Logout</li>

      <li>
        <Link
          style={{ color: "black" }}
          to={
            role === "employee" || role === "foreman"
              ? "/employee/config"
              : role === "projectmanager" ||
                  role === "estimator" ||
                  role === "executive"
                ? "/exeemployee/config"
                : role === "subcontractor"
                  ? "/subcontractor/config"
                  : "/company/config"
          }
        >
          Edit Profile
        </Link>
      </li>
    </ul>
  );
  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Avatar
        src={
          props.auth.us.logo
            ? props.auth.us.logo
            : "https://via.placeholder.com/150"
        }
        className="gx-size-40 gx-pointer gx-mr-3"
        alt=""
      />
    </Popover>
  );
};
const mapStateToProps = (state) => ({
  auth: state.authR,
});

export default connect(mapStateToProps, { logoutUser })(UserInfo);
