import React from "react";
// 3rd party libraries
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Form, Input } from "antd";
// custom imports
import {
  loginUser,
  loginAs,
  logoutUser,
} from "../appRedux/actions/AuthActions";
import { get } from "lodash";

class SignIn extends React.PureComponent {
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const by = searchParams.get("by");
    if (by === "admin") {
      this.props.logoutUser();
      const id = searchParams.get("id");
      this.props.loginAs(id);
    }

    if (get(this.props, "auth.isAuthenticated")) {
      if (
        get(this.props, "auth.user.role") === "company" ||
        get(this.props, "auth.user.role") === "administrator"
      ) {
        this.props.history.push("/company/projects");
      } else if (
        get(this.props, "auth.user.role") === "executive" ||
        get(this.props, "auth.user.role") === "estimator" ||
        get(this.props, "auth.user.role") === "projectmanager"
      ) {
        this.props.history.push("/exeemployee/checkInOut");
      } else if (get(this.props, "auth.user.role") === "subcontractor") {
        this.props.history.push("/subcontractor/myworkorders");
      } else {
        if (
          get(this.props, "auth.user.role") === "employee" ||
          get(this.props, "auth.user.role") === "foreman"
        ) {
          this.props.history.push("/employee/checkInOut");
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (get(nextProps, "auth.isAuthenticated")) {
      if (
        nextProps.auth.user["role"] === "company" ||
        nextProps.auth.user["role"] === "administrator"
      ) {
        this.props.history.push("/company/projects"); // push user to dashboard when they login
      } else if (
        nextProps.auth.user["role"] === "executive" ||
        nextProps.auth.user["role"] === "projectmanager" ||
        nextProps.auth.user["role"] === "estimator"
      ) {
        this.props.history.push("/exeemployee/checkInOut");
      } else if (nextProps.auth.user["role"] === "subcontractor") {
        this.props.history.push("/subcontractor/myworkorders");
      } else {
        this.props.history.push("/employee/checkInOut"); // push user to dashboard when they login
      }
    }

    if (nextProps.errors) {
      this.setState({
        errors: get(nextProps, "errors"),
      });
    }
  }
  handleSubmit = (values) => {
    this.props.loginUser(values);
  };

  render() {
    return (
      <div className="gx-app-login-wrap" style={{ marginTop: "37px" }}>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img
                  src="https://s3.us-east-1.wasabisys.com/constructions/static/acts-round.png"
                  alt="Neature"
                />
              </div>
              <div className="gx-app-logo-wid"></div>
            </div>
            <div className="gx-app-login-content">
              <Form
                initialValues={{ remember: true }}
                name="basic"
                onFinish={this.handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "The input is not valid UserId or Username!",
                    },
                  ]}
                  name="email"
                >
                  <Input placeholder="Username or User ID" />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                  name="password"
                >
                  <Input type="password" placeholder="Password" />
                </Form.Item>
                <div>
                  <Link
                    to="/forgot"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <span>Forgot Your Password ?</span>
                  </Link>
                </div>
                <div>
                  <Link
                    to="/forgot-username"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <span>Forgot Your Username ?</span>
                  </Link>
                </div>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    signIn
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.authR,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  loginUser,
  loginAs,
  logoutUser,
})(SignIn);
