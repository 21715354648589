import React from "react";
import { useSelector } from "react-redux";

// custom imports
import { get } from "lodash";
import Sidebar from "../Sidebar";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../../constants/ThemeSetting";

const SIDEBAR_VISIBLE_ON = [
  NAV_STYLE_FIXED,
  NAV_STYLE_DRAWER,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
];

const AppSidebar = ({ navStyle }) => {
  const width = useSelector(({ common }) => get(common, "width"));

  if (width < TAB_SIZE || SIDEBAR_VISIBLE_ON.includes(navStyle)) {
    return <Sidebar />;
  }

  return null;
};

export default React.memo(AppSidebar);
