import React from "react";
import { Route } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

import "./styling.css";

const App = ({ match, role }) => (
  <div className="gx-main-content-wrapper">
    {role === "company" ? (
      <Route
        oute
        excat
        path={`${match.url}`}
        component={asyncComponent(() => import("./CompanyRoute"))}
      />
    ) : role === "excemployee" ? (
      <Route
        excat
        path={`${match.url}`}
        component={asyncComponent(() => import("./ExeRoute"))}
      />
    ) : role === "subcontractor" ? (
      <Route
        excat
        path={`${match.url}`}
        component={asyncComponent(() => import("./SubContractorRoute"))}
      />
    ) : (
      <Route
        excat
        path={`${match.url}`}
        component={asyncComponent(() => import("./EmployeeRoute"))}
      />
    )}
  </div>
);

export default App;
