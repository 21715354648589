import React from "react";
// 3rd party libraries
import axios from "axios";
import { connect } from "react-redux";
// custom components
import config from "../config";
import { logoutUser } from "../appRedux/actions/AuthActions";
import { get } from "lodash";

class VerifyingEmail extends React.PureComponent {
  state = {
    verified: false,
    loading: true,
  };
  handleSendVerificationForEmail = (otp) => {
    axios
      .post(`${config.API_URL}/company/subcontractor/verify-email/${otp}`)
      .then((response) => {
        if (get(response, "data.success")) {
          this.props.logoutUser();
          this.setState({ loading: false, verified: true });
          window.location.href = "/login";
        } else {
          this.setState({ loading: false, verified: false });
        }
      });
  };

  componentDidMount() {
    const { match } = this.props;
    const { otp } = match.params;
    this.handleSendVerificationForEmail(otp);
  }

  render() {
    return (
      <div className="gx-app-login-wrap" style={{ marginTop: "37px" }}>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img
                  src={
                    "https://s3.us-east-1.wasabisys.com/constructions/static/acts-round.png"
                  }
                  alt="Neature"
                />
              </div>
              <div className="gx-app-logo-wid"></div>
            </div>
            <div className="gx-app-login-content">
              {!get(this.state, "loading") ? (
                <h1>{!get(this.state, "verified") ? "404" : "verified"}</h1>
              ) : (
                <h1>verifying...</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.authR,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  logoutUser,
})(VerifyingEmail);
