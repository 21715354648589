import React from "react";
// 3rd party libraries
import { connect } from "react-redux";
import { Button, Form, Input } from "antd";
// custom imports
import { register } from "../appRedux/actions/AuthActions";

class SignUp extends React.PureComponent {
  handleSubmit = (values) => {
    this.props.register(values);
  };

  render() {
    return (
      <div className="gx-app-login-wrap" style={{ marginTop: "37px" }}>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img
                  src={
                    "https://s3.us-east-1.wasabisys.com/constructions/static/acts-round.png"
                  }
                  alt="Neature"
                />
              </div>
              <div className="gx-app-logo-wid"></div>
            </div>
            <div className="gx-app-login-content">
              <Form
                initialValues={{ remember: true }}
                name="basic"
                onFinish={this.handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <Form.Item
                  rules={[
                    { required: true, message: "Please Input Your Name" },
                  ]}
                  name="name"
                >
                  <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Please Input Your Address" },
                  ]}
                  name="address"
                >
                  <Input placeholder="Address" />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Please Input Your Username" },
                  ]}
                  name="username"
                >
                  <Input placeholder="Username" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                  name="email"
                >
                  <Input placeholder="Email" type="email" />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Please input your Password!" },
                    {
                      min: 6,
                      message: "Password Should be Minimum 6 characters",
                    },
                  ]}
                  name="password"
                >
                  <Input type="password" placeholder="Password" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    signUp
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.authR,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  register,
})(SignUp);
