import React from "react";

const CircularProgress = ({ className }) => (
  <div className={`loader ${className}`}>
    <img
      src="https://s3.us-east-1.wasabisys.com/constructions/static/acts-spinner.gif"
      alt="loader"
    />
  </div>
);
export default CircularProgress;
