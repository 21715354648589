import React from "react";
// 3rd party libraries
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, message, Input } from "antd";
// custom imports
import config from "config";
import { get } from "lodash";

class Forgot extends React.Component {
  state = {
    email: "",
    sent: false,
  };

  componentDidMount() {
    if (get(this.prop, "auth.isAuthenticated")) {
      if (get(this.props, "auth.user.role") === "admin") {
        this.props.history.push("/admin/dashboard");
      } else if (get(this.props, "auth.user.role") === "doctor") {
        this.props.history.push("/doctor/dashboard");
      } else {
        this.props.history.push("/sale/dashboard");
      }
    }
  }
  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: get(e, "target.value"),
    });
  };
  emailChange = (e) => {
    axios
      .post(`${config.API_URL}/auth/forgotpassword`, {
        email: get(this.state, "email"),
      })
      .then((response) => {
        if (get(response, "data.success") === false) {
          message.error("Email Not Sent");
        } else {
          message.success("Email Sent Please Check Your Email");
          this.setState({ email: "", sent: true });
        }
      })
      .catch((err) => {
        message.error(get(err, "message"));
      });
  };

  render() {
    return (
      <div className="gx-app-login-wrap" style={{ marginTop: "29px" }}>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img
                  src={
                    "https://s3.us-east-1.wasabisys.com/constructions/static/acts-round.png"
                  }
                  alt="Neature"
                />
              </div>
            </div>

            <div
              className="gx-app-login-content"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <h2>Forgot Password</h2>
              </div>

              <div
                style={{ padding: 15, width: "100%" }}
                className="form-group"
              >
                <Input
                  type="email"
                  placeholder="Enter Your User ID or Username"
                  name="email"
                  value={get(this.state, "email")}
                  onChange={this.handleOnChange}
                />
              </div>
              <div style={{ padding: 15 }}>
                <Button type="primary" onClick={this.emailChange}>
                  Send Email
                </Button>
              </div>
              <div>
                <Link to="/forgot-username" style={{ margin: "10 0" }}>
                  <span>Forgot Your Username ?</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.authR,
});
export default connect(mapStateToProps)(Forgot);
