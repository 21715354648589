import React from "react";
// 3rd party libraries
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
// custom imports
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../../constants/ThemeSetting";
import IntlMessages from "../../../util/IntlMessages";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed, auth }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="sample">
              <Link to="/employee/dashboard">
                <i className="icon icon-widgets" />
                <span>
                  <IntlMessages id="sidebar.dashboard" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="projects">
              <Link to="/employee/checkInOut">
                <i className="icon icon-widgets" />
                <span>
                  <IntlMessages id="sidebar.clockInOut" />
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="workorders">
              <Link to="/employee/team">
                <i className="icon icon-widgets" />
                <span>
                  <IntlMessages id="sidebar.team" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="myprojects">
              <Link to="/employee/projects">
                <i className="icon icon-widgets" />
                <span>Projects</span>
              </Link>
            </Menu.Item>

            {auth.user.role === "foreman" && (
              <Menu.Item key="Files Manager">
                <Link to="/employee/files">
                  <i className="icon icon-widgets" />
                  <span>
                    <IntlMessages id="sidebar.files" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="Files">
              <Link to="/employee/myfiles">
                <i className="icon icon-widgets" />
                <span>My Files</span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authR,
});
const Memo = React.memo(SidebarContent);
export default connect(mapStateToProps)(Memo);
