import React from "react";
// 3rd party libraries
import axios from "axios";
import { connect } from "react-redux";
import { Button, message, Input } from "antd";
// custom imports
import config from "config";
import { get } from "lodash";

class Forgot extends React.Component {
  state = {
    newpassword: "",
    confirmpassword: "",
  };

  componentDidMount() {
    if (get(this.props, "auth.isAuthenticated")) {
      if (get(this.props, "auth.user.role") === "admin") {
        this.props.history.push("/admin/dashboard");
      } else if (get(this.props, "auth.user.role") === "doctor") {
        this.props.history.push("/doctor/dashboard");
      } else {
        this.props.history.push("/sale/dashboard");
      }
    }
  }
  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: get(e, "target.value"),
    });
  };
  ResetPassword = (e) => {
    if (get(this.state, "newpassword") !== get(this.state, "confirmpassword")) {
      message.error("Password Not Confirmed");
    } else {
      axios
        .put(
          `${config.API_URL}/auth/resetpassword/${get(this.props, "match.params.token")}`,
          { password: get(this.state, "confirmpassword") }
        )
        .then((response) => {
          if (!get(response, "data.success")) {
            message.error(get(response, "data.msg"));
          } else {
            message.success("Your Password is Changed");
            this.props.history.push("/login");
          }
        })
        .catch((err) => {
          message.error(get(err, "message"));
        });
    }
  };

  render() {
    return (
      <div className="gx-app-login-wrap" style={{ marginTop: "37px" }}>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img
                  src={
                    "https://s3.us-east-1.wasabisys.com/constructions/static/acts-round.png"
                  }
                  alt="Neature"
                />
              </div>
              <div className="gx-app-logo-wid"></div>
            </div>

            <div className="gx-app-login-content">
              <div
                style={{ padding: 15, marginTop: 20 }}
                className="form-group"
              >
                <Input
                  type="password"
                  placeholder="Enter New Password"
                  name="newpassword"
                  value={get(this.state, "newpassword")}
                  onChange={this.handleOnChange}
                />
              </div>
              <div style={{ padding: 15 }} className="form-group">
                <Input
                  type="password"
                  placeholder="Enter Confirm Password"
                  name="confirmpassword"
                  value={get(this.state, "confirmpassword")}
                  onChange={this.handleOnChange}
                />
              </div>
              <div style={{ padding: 15, marginLeft: 70 }}>
                <Button type="primary" onClick={this.ResetPassword}>
                  Reset Password
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.authR,
});
export default connect(mapStateToProps)(Forgot);
