import React from "react";
// 3rd party libraries
import { Button } from "antd";
import { connect } from "react-redux";

const UserProfile = (props) => {
  return (
    <div>
      <Button>
        <a
          target="_blank"
          style={{ color: "black" }}
          href={
            `https://localhost/stripe/create-checkout-session?email=` +
            encodeURIComponent(`${props.auth.authR.user.email}`) +
            `&actsAccount=${props.auth.authR.user.id}`
          }
          rel="noreferrer"
        >
          Upgrade
        </a>
      </Button>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state,
});

export default connect(mapStateToProps)(UserProfile);
