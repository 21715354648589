import React from "react";
// 3rd party libraries
import { Avatar, Popover } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// custom imports
import { logoutUser } from "../../../appRedux/actions/AuthActions";

const UserProfile = (props) => {
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>
        <Link style={{ color: "black" }} to="/subcontractor/config">
          Edit Profile
        </Link>
      </li>

      <li onClick={() => props.logoutUser()}>Logout</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          src={
            props.auth.authR.us.logo
              ? props.auth.authR.us.logo
              : "https://via.placeholder.com/150"
          }
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="gx-avatar-name">
          {props.auth.authR.user.name}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state,
});

export default connect(mapStateToProps, { logoutUser })(UserProfile);
