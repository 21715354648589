import React from "react";

import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import Reset from "./containers/Reset";
import SignIn from "./containers/SignIn";
import SignUp from "./containers/SignUp";
import Forgot from "./containers/Forgot";
import EmployeeRoute from "./util/SLEmployee";
import setAuthToken from "./util/setAuthToken";
import CompanyRoute from "./util/CompanyRoute";
import ConvertSub from "./ConvertSub/index.js";
import CompanyApp from "./containers/Company/App";
import EmployeeApp from "./containers/Employee/App";
import ExeEmployeeRoute from "./util/EmployeeRoute";
import VerifyEmail from "./containers/VerifyEmail.js";
import ExeEmployee from "./containers/ExeEmployee/App";
import SubContractorRoute from "./util/SubContractorRoute";
import configureStore, { history } from "./appRedux/store";
import VerifyingEmail from "./containers/VerifyingEmail.js";
import ForgotUsername from "./containers/ForgotUsername.js";
import SubContractorApp from "./containers/SubContractor/App";
import { setCurrentUser, setUser } from "./appRedux/actions/AuthActions";

import "assets/vendors/style";
import "styles/wieldy.less";
import NotFound from "./components/NotFound.js";

const store = configureStore();

if (localStorage.jwtToken) {
  // Set auth token header auth

  const token = localStorage.jwtToken;

  setAuthToken(token);
  // Decode token and get user info and exp

  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
  store.dispatch(setUser());
  // Set user and isAuthenticated
}

const NextApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/" component={SignIn} />
        <Route path="/login" component={SignIn} />
        <Route path="/verify-your-email" component={VerifyEmail} />
        <Route path="/verify-email/:otp" component={VerifyingEmail} />
        <Route path="/register" component={SignUp} />
        <Route path="/convert/:id" component={ConvertSub} />

        <Route path="/forgot" component={Forgot} />
        <Route path="/forgot-username" component={ForgotUsername} />
        <Route path="/reset/:token" component={Reset} />
        <CompanyRoute path="/company" component={CompanyApp} />
        <ExeEmployeeRoute path="/exeemployee" component={ExeEmployee} />
        <SubContractorRoute
          path="/subcontractor"
          component={SubContractorApp}
        />
        <EmployeeRoute path="/employee" component={EmployeeApp} />
        <Route path="*" component={NotFound} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default NextApp;
